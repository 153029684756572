<template>
  <v-col cols="12">
    <v-form ref="form">
      <base-card>
        <v-card-title>Facturas Electrónicas</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4">
              <v-select v-model="dataFilter.origin" :items="origin" item-text="textOrigin" item-value="valueOrigin"
                label="Origen Transacción" prepend-inner-icon="mdi-adjust" />
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                ref="RefProveedor"
                v-model="dataFilter.ProveedorTecnologico"
                :items="listTechnologyProviderComp"
                item-text="textEnvironment"
                item-value="valueEnvironment"
                label="Proveedor tecnológico"
                prepend-inner-icon="mdi-server"
                required
              />
            </v-col>
            <v-col cols="12" sm="4" v-if="isAdmin">
              <v-select
                ref="cliente"
                v-model="dataFilter.IdCompania"
                :items="detailClients"
                item-text="text"
                item-value="IdCompania"
                label="Compañia"
                prepend-inner-icon="mdi-sitemap"
                :rules="formRules"
                :error-messages="errorMessages"
                filter
                search-input
                required
              >
                <template v-slot:prepend-item v-if="isAdmin">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-text-field
                          class="search-field"
                          v-model="search"
                          placeholder="Buscar"
                          prepend-inner-icon="mdi-magnify"
                          hide-details
                        />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <v-dialog ref="dialog" v-model="modal" :return-value.sync="dates" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dates" label="Rango de fechas" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" />
                </template>
                <v-date-picker v-model="dates" locale="es" show-current="true">
                  <v-spacer />
                  <v-btn text color="primary" @click="getCleanDateCurrent()">
                    Limpiar
                  </v-btn>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>



            <v-col cols="12" sm="4">
              <v-select v-model="dataFilter.Status" :items="typeStatus" item-text="textStatus" item-value="valueStatus"
                label="Estado" prepend-inner-icon="mdi-order-bool-descending-variant" />
            </v-col>


          </v-row>
          <v-row>
            <v-col cols="12" sm="4" v-if="showPrefixField">
              <v-text-field v-model="dataFilter.Prefix" label="Prefijo" prepend-inner-icon="mdi-file" />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="dataFilter.Consecutivo" label="Número de factura (Prefijo y consecutivo)"
                prepend-inner-icon="mdi-note-multiple" :rules="invoiceNumberRules" />
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn class="ma-2" style="background-color:rgb(204 33 40);color:white !important" @click="searchInvoice">
                Buscar
              </v-btn>
              <v-btn class="ma-2" color="GRAY" @click="clearForm">
                Nueva Búsqueda
              </v-btn>
            </v-col>
            <v-row v-if="searchInvoiceProgress == true" class="fill-height" align-content="center" justify="center">
              <v-col cols="6">
                <v-col class="text-subtitle-1 text-center" cols="12">
                  Consultando Facturas
                </v-col>
                <v-progress-linear color="red darken-4" indeterminate rounded height="6" />
              </v-col>
            </v-row>
            <v-alert v-show="successAlert" type="success" text>
              {{ msgSuccessAlert }}
            </v-alert>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-alert v-if="showAlert" :type="alertType" text>
                {{ alertMessage }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </base-card>
    </v-form>
  </v-col>
</template>

<script>
import { listStatus } from '@/data/listStatus'
import { listOrigin } from '@/data/listOrigin'
import { listTechnologyProvider } from "@/data/listEnvironment";
import { mapState } from 'vuex'

export default {
  name: 'FilterInvoices',
  data: () => ({
    listTechnologyProviderNew: [...listTechnologyProvider],
    notFoundInvoices: false,
    searchInvoiceProgress: false,
    search: '',
    showPrefix: true,
    modal: false,
    datepickerFinal: false,
    dateFinal: null,
    typeStatus: listStatus,
    origin: listOrigin,
    dataFilter: { ProveedorTecnologico: "Todos", origin: "Todos", IdCompania: "", Status: "", Prefix: "", Consecutivo: "" },
    msgSuccessAlert: '',
    invoiceNumberRules: [],
    successAlert: false,
    dates: null,
    alertMessage: '',
    alertType: 'info',
    showAlert: false,

  }),
  mounted() {
  this.dates = this.getDateCurrent()
  },

  beforeDestroy() {
    this.$store.commit("invoice/resetListInvoice");
  },

  created() {
    this.searchInvoiceProgress = false
    const newElement = { textEnvironment: "Todos", valueEnvironment: "Todos" }
    this.listTechnologyProviderNew.unshift(newElement)

    this.isAdmin =
      JSON.parse(atob(localStorage.getItem("uid"))).rol === "Administrador"

    if (!this.isAdmin) {
      this.dataFilter.IdCompania = JSON.parse(
        atob(localStorage.getItem("uid"))
      ).idcompania
      this.getFilterInvoices()
    }

    this.getListClients()
  },
  computed: {
    ...mapState("invoice", ["listInvoice", "runListInvoice"]),
    listTechnologyProviderComp() {
      if (
        JSON.parse(atob(localStorage.getItem("uid"))).rol === "Administrador"
      ) {
        return this.listTechnologyProviderNew;
      }

      let loginUserData = this.$store.state.authentication.loginUserData;
      if (
        loginUserData &&
        !loginUserData.Error &&
        loginUserData.data &&
        loginUserData.data.user_compania
      ) {
        let proveedor = loginUserData.data.user_compania.ProveedorTecnologico;
        return this.listTechnologyProviderNew.filter(
          (item) => item.valueEnvironment == proveedor
        );
      }

      return this.listTechnologyProviderNew;
    },

    detailClients() {
      if (this.isAdmin) {
        let activeCompanies = (
          this.$store.state.invoice.listClients.Mensaje || []
        )
          .filter((compania) => compania.Activo === 1)
          .filter((compania) =>
            compania.NombreCompania.toLowerCase().includes(
              this.search.toLowerCase()
            )
          )
          .map((compania) => ({
            ...compania,
            text: `[${compania.IdCompania}] ${compania.NombreCompania}`,
          }));

        return activeCompanies;
      }

      return (this.$store.state.invoice.listClients.Mensaje || []).filter(
        (compania) =>
          compania.IdCompania ===
          JSON.parse(atob(localStorage.getItem("uid"))).idcompania
      );
    },
    showPrefixField() {
      if (this.isAdmin) {
        return true;
      } else {
        return this.dataFilter.Status !== "W";
      }
    },
  },
  watch: {
    loginUserData(val) {
    },
    runListInvoice() {
      if(this.runListInvoice){
        this.$store.dispatch("invoice/setRunListInvoiceData", false);
        this.searchInvoice();
      }
    },
    listInvoice() {
      if (this.$store.state.invoice.listInvoice.Mensaje) {
        this.searchInvoiceProgress = false
        if (this.$store.state.invoice.listInvoice.Mensaje.length > 0) {
          this.notFoundInvoices = false
        } else if (this.$store.state.invoice.listInvoice.Mensaje.length == 0) {
          this.notFoundInvoices = true
          this.alertMessage = 'No se encontraron facturas para los filtros aplicados.'
          this.alertType = 'info'
          this.showAlert = true;
        }
      }
    },
    dates(){
     this.dataFilter.dates = this.dates;
    }
  },
  methods: {
    generateAlertSuccess() {
      this.successAlert = true
      setTimeout(() => {
        this.successAlert = false
      }, 3000)
    },

    async getListClients () {
      await this.$store.dispatch('invoice/getListClients')
    },

    searchInvoice() {
      if (!this.dates || this.dates.length === 0 || this.dates[0] === "" || this.dates[1] === "") {
      this.alertMessage = 'Por favor, seleccione una fecha.';
      this.alertType = 'error';
      this.showAlert = true;
      return;
    }

    if (!this.dataFilter.Prefix && !this.dataFilter.Consecutivo  && this.dataFilter.Status === "W") {
      this.alertMessage = 'Debe ingresar un valor en el campo Prefijo o Número de factura.';
      this.alertType = 'error';
      this.showAlert = true;
      return;
    }

    if (this.isAdmin) {
        if (!this.dataFilter.IdCompania || this.dataFilter.IdCompania === "") {
          this.alertMessage = "Debe seleccionar una compañía.";
          this.alertType = "error";
          this.showAlert = true;
          return;
        }
      }

      if (!this.dataFilter.Status || this.dataFilter.Status === "") {
        this.alertMessage = "Debe seleccionar un estado.";
        this.alertType = "error";
        this.showAlert = true;
        return;
      }

      this.showAlert = false;

      this.notFoundInvoices = false
      this.searchInvoiceProgress = true
      this.$store.dispatch('invoice/getListInvoice', {
        ...this.dataFilter,
      })
    },
    getDateCurrent(){
      const dateCurrent = new Date();
      return dateCurrent.getFullYear() + '-' + String(dateCurrent.getMonth() + 1).padStart(2, '0') + '-' + String(dateCurrent.getDate()).padStart(2, '0')
    },
    getFilterInvoices() {
      this.isLoading = true
      this.$store.dispatch('invoice/getFilterInvoices', {
        ...this.dataFilter
      })
    },
    getCleanDateCurrent() {
      this.dates = null
      this.$refs.dialog.save(this.dates)
    },
    clearForm() {
      this.dataFilter = {
        ProveedorTecnologico: "Todos",
        origin: "Todos",
        IdCompania: "",
      };
      this.search = "";
      this.notFoundInvoices = false;
      this.searchInvoiceProgress = false;
      this.showAlert = false;
      this.$store.commit("invoice/setListInvoice", { Mensaje: [] });
    },
  },
}
</script>

<style scoped></style>
