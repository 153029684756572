export const listStatus = [
  { textStatus: 'Procesada', valueStatus: 'W' },
  { textStatus: 'Pendiente por procesar', valueStatus: 'P' },
  { textStatus: 'Rechazadas', valueStatus: 'E' },
]


export const representation = [
  { textRepresentation: 'Standard', valueRepresentation: 'Standard' },
  //{ textRepresentation: 'Plus', valueRepresentation: 'Plus' },
]